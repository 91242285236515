<style lang="scss">
#login-client {
  .text-info {
    cursor: pointer;
  }
}
</style>
<template>
  <div id="login-client">
    <div class="container">
      <div id="login-row" class="row justify-content-center align-items-center">
        <div id="login-column card" class="col-md-6 card mt-40">
          <div id="login-box" class="col-md-12 card-body">
            <form id="login-form" class="form" @submit.stop.prevent="onSubmit">
              <h3 class="text-center text-info" v-if="client_slug != ''">
                <img
                  :src="
                    getServiceProviderData.image == null
                      ? getServiceProviderData.logo
                      : getServiceProviderData.image
                  "
                  style="width: 25%"
                  alt="logo"
                />
              </h3>
              <hr v-if="client_slug != ''" />
              <div
                class="alert alert-danger"
                role="alert"
                v-if="errorMsg != ''"
              >
                {{ errorMsg }}
              </div>
              <div class="form-group">
                <label for="username" class="text-dark text-uppercase"
                  >Username</label
                >
                <input
                  type="text"
                  v-model="username"
                  class="form-control"
                  :class="
                    errors['username'] && this.username == ''
                      ? 'border border-danger'
                      : ''
                  "
                />
                <span
                  v-if="errors['username'] && username == ''"
                  class="text-danger"
                >
                  {{ errors["username"] }}
                </span>
              </div>
              <div class="form-group">
                <label for="password" class="text-dark text-uppercase"
                  >Password</label
                >
                <div style="position: relative">
                  <input
                    :type="password_type"
                    v-model="password"
                    class="form-control"
                    :class="
                      errors['password'] && this.password == ''
                        ? 'border border-danger'
                        : ''
                    "
                  />
                  <a @click="showPassword()" style="cursor: pointer">
                    <i
                      :class="
                        showPasswordIcon
                          ? 'fa fa-eye text-info'
                          : 'fa fa-eye-slash'
                      "
                      style="position: absolute; right: 10px; top: 12px"
                    ></i
                  ></a>
                </div>

                <span
                  v-if="errors['password'] && password == ''"
                  class="text-danger"
                >
                  {{ errors["password"] }}
                </span>
              </div>

              <button
                style="float: right"
                ref="kt_login_signin_submit"
                class="btn btn-info btn-md mt-0"
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN, LOGOUT } from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      // Remove this dummy login info
      errorMsg: "",
      username: "",
      password: "",
      showPasswordIcon: false,
      password_type: "password",
      client_slug: "",
      errors: [],
    };
  },

  methods: {
    showPassword() {
      if (!this.showPasswordIcon) {
        this.showPasswordIcon = true;
        this.password_type = "text";
      } else {
        this.showPasswordIcon = false;
        this.password_type = "password";
      }
    },

    onSubmit() {
      this.errors = [];
      this.errorMsg = "";
      if (this.password == "" || this.username == "") {
        if (this.password == "" && this.username == "") {
          this.errors.password = "This field is required";
          this.errors.username = "This field is required";
        } else if (this.username == "") {
          this.errors.username = "This field is required";
        } else if (this.password == "") {
          this.errors.password = "This field is required";
        }
      } else {
        const username = this.username;
        const password = this.password;
        const client_slug = this.client_slug;
        // clear existing errors
        this.$store.dispatch(LOGOUT);
        // s spinner to submit button
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.disabled = true;
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        this.$store
          .dispatch(LOGIN, { username, password, client_slug })
          // go to which page after successfully login
          .then(() => {
            // console.log(data);

            this.$router.push({
              name: "client.dailyovenreports",
            });

            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
          })
          .catch(() => {
            this.errorMsg = "Invalid username or password";
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
          });
      }
    },
  },
};
</script>
